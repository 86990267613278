<template lang="pug">
div.d-flex.flex-column
  HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :indexSearch="indexSearch" :homepageMode="homepageMode" :homepage="homepage")
  .d-flex.w-100.h-100(style="overflow: hidden;")
    .text-center.flex-center.w-100(v-if='isLoadingContactsList || isLoadingContactsListArchived || !headerLoaded')
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement de la liste des contacts...
    sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" :allowGrouping="allowGrouping")
  ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
    sidebar-contact-content(ref='sidebarContactContent' @closeSidebar="closeSidebar" @refreshGrid="refreshGrid")
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import SidebarContactContent from "@/components/directory/contact/SidebarContactContent";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  components: {
    SidebarContactContent,
    SyncGrid,
    HeaderGrid,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      homepageMode: true,
      homepage: false,
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "contact",
      uid: "contacts",
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      tagsSelected: [],
      selectedRows: [],

      tvaMode: "HT",
      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: true,
      archiveMode: true,
      archive: false,
      searchPlaceholder: "Rechercher un contact",
      allowGrouping: true,
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: true,
      },
      {
        type: "string",
        field: "civility",
        filterTemplate: "civilityFilterTemplate",
        headerText: "Civilité",
        width: 100,
        maxWidth: 120,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        template: "civilityTemplate",
        visible: true,
      },
      {
        type: "string",
        field: "lastName",
        headerText: "Nom",
        width: "auto",
        minWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "firstName",
        headerText: "Prénom",
        width: "auto",
        minWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "email",
        headerText: "E-mail",
        width: "auto",
        minWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "phoneNumber",
        headerText: "Téléphone",
        width: 120,
        minWidth: 120,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "secondaryPhoneNumber",
        headerText: "Tél secondaire",
        width: 120,
        minWidth: 120,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "activity",
        headerText: "Activité",
        width: 120,
        minWidth: 120,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "contactTags",
        headerText: "Tags",
        width: "auto",
        minWidth: 200,
        allowFiltering: false,
        allowSorting: false,
        showInColumnChooser: true,
        visible: true,
        clipMode: "EllipsisWithTooltip",
        template: "tagsTemplate",
        unavailableExport: true,
      },
    ];
  },
  computed: {
    ...mapGetters([
      "contactsList",
      "contactsListArchived",
      "isLoadingContactsList",
      "isLoadingContactsListArchived",
    ]),
    dataFiltered() {
      if (this.archive) {
        return this.$store.getters[this.name + "sListArchived"];
      } else {
        return this.$store.getters[this.name + "sList"];
      }
    },
  },
  async created() {
    this.getContacts({}).then(async (res) => {
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
    this.getContactTags({});
  },
  methods: {
    ...mapActions(["getContacts", "getContactsArchived", "getContactTags"]),
    setColumnsByObject,
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.$router.push({ path: "/directory/new-contact" });
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.getContactsArchived({});
        this.archive = true;
      } else if (args.item.type == "current") {
        this.getContacts({});
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.isOpen = true;
      if (this.archive) {
        this.$refs["sidebarContactContent"].getContactArchivedDetails(
          event.rowData.id,
          1
        );
      } else {
        this.$refs["sidebarContactContent"].getContactDetails(event.rowData.id);
      }
      this.$refs.sidebar.toggle();
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    closeSidebar(res) {
      this.$refs.sidebar.hide();
    },
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
  },
};
</script>
