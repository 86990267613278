<template>
  <div
    v-if="isLoadingContact || isUpdatingContact || isDeletingContact"
    class="h-100"
  >
    <div class="text-center flex-center h-100">
      <div class="loading-bg-inner">
        <div class="loader">
          <div class="outer"></div>
          <div class="middle"></div>
          <div class="inner"></div>
        </div>
      </div>
      <div style="margin-top: 7rem">Chargement des détails du contact...</div>
    </div>
  </div>

  <div v-else-if="contact" class="h-100">
    <div class="sidebar-header py-1 px-2">
      <div class="d-flex align-items-center">
        <div>
          <h3>
            {{
              contact.civility == 0
                ? "M. "
                : contact.civility == 1
                ? "Mme. "
                : ""
            }}
            {{
              (contact.firstName ? contact.firstName : "") +
              " " +
              contact.lastName
            }}
          </h3>
          <small>{{ contact.activity }}</small>
        </div>
      </div>
      <feather-icon
        icon="XIcon"
        size="24"
        @click.stop="closeSidebar"
        class="close-icon cursor-pointer"
      ></feather-icon>
    </div>

    <vs-divider class="m-0" />

    <div class="sidebar-action py-05 px-2">
      <div class="d-flex align-items-center">
        <b-button
          v-if="archived"
          variant="warning"
          class="btn-icon"
          @click="
            restoreContactLocal(
              contact.id,
              contact.firstName + ' ' + contact.lastName
            )
          "
        >
          <feather-icon icon="ArrowDownIcon" /> Restaurer
        </b-button>
        <b-button
          v-else
          class="btn-icon"
          variant="primary"
          @click="editContact(contact.id)"
          :disabled="archived"
        >
          <feather-icon icon="EditIcon" /> Modifier
        </b-button>
        <!-- <vs-dropdown class="cursor-pointer chip">
                      <span class="point" :class="'bg-'+contact.status.class"></span>
                      <span class="label" :class="'text-'+contact.status.class">{{contact.status.label}}</span>
                      <vs-dropdown-menu style="z-index: 200001">
                          <span class="cursor-pointer chip"  @click.stop v-for="(status, index) in statusList" :key="index">
                              <span class="point" :class="'bg-'+status.class"></span>
                              <span class="label" :class="'text-'+status.class">{{status.label}}</span>
                          </span>
                      </vs-dropdown-menu>
                  </vs-dropdown> -->
      </div>
      <b-button-group>
        <b-button
          v-if="archived"
          variant="outline-danger"
          class="btn-icon"
          @click="
            deleteContactLocal(
              contact.id,
              contact.firstName + ' ' + contact.lastName
            )
          "
        >
          <feather-icon icon="TrashIcon" /> Supprimer définitivement
        </b-button>
        <b-button
          v-else
          variant="outline-danger"
          class="btn-icon"
          @click="
            archiveContactLocal(
              contact.id,
              contact.firstName + ' ' + contact.lastName
            )
          "
        >
          <feather-icon icon="ArchiveIcon" />
        </b-button>
      </b-button-group>
    </div>

    <vs-divider class="m-0" />

    <div class="sidebar-content">
      <div
        v-show="
          contact.secondaryPhoneNumber || contact.phoneNumber || contact.email
        "
      >
        <h4 class="mb-1 text-primary">
          <feather-icon icon="PhoneCallIcon" /> Coordonnées
        </h4>
        <ul class="listTab">
          <li v-show="contact.email">
            <span>Adresse e-mail</span>
            <span>{{ contact.email }}</span>
          </li>
          <li v-show="contact.phoneNumber">
            <span>Téléphone principal</span>
            <span>{{ contact.phoneNumber }}</span>
          </li>
          <li v-show="contact.secondaryPhoneNumber">
            <span>Téléphone secondaire</span>
            <span>{{ contact.secondaryPhoneNumber }}</span>
          </li>
        </ul>
        <vs-divider />
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      archived: false,
      contact: {},
    };
  },
  computed: {
    ...mapGetters([
      "isUpdatingContact",
      "compagniesList",
      "isLoadingContact",
      "isDeletingContact",
    ]),
  },
  methods: {
    ...mapActions([
      "deleteContacts",
      "isArchivingContact",
      "archiveContacts",
      "restoreContacts",
      "getContactById",
      "getContactArchivedById",
    ]),
    getContactArchivedDetails(id, archived) {
      this.archived = archived;
      if (id > 0) {
        this.getContactArchivedById({ contactId: id }).then((res) => {
          this.contact = res;
        });
      } else {
        this.contact = {};
      }
    },
    getContactDetails(id) {
      this.archived = false;
      if (id > 0) {
        this.getContactById({ contactId: id }).then((res) => {
          this.contact = res;
        });
      } else {
        this.contact = {};
      }
    },
    closeSidebar() {
      this.$emit("closeSidebar");
      this.$emit("refreshGrid");
    },
    async editContact(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-contact",
        params: {
          id: id,
          title:
            "Edit : " + this.contact.firstName + " " + this.contact.lastName,
          tips:
            "Editer le contact : " +
            this.contact.firstName +
            " " +
            this.contact.lastName,
        },
      });
    },
    archiveContactLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveContacts({ contactIds: [id] }).then((res) => {
              this.closeSidebar();
            });
          }
        });
    },
    restoreContactLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.",
          {
            title: 'Êtes-vous sûr de vouloir restaurer "' + label + '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restoreContacts({ contactIds: [id] }).then((res) => {
              this.closeSidebar();
            });
          }
        });
    },
    deleteContactLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: 'Êtes-vous sûr de vouloir supprimer "' + label + '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteContacts({ contactIds: [id] }).then((res) => {
              this.closeSidebar();
            });
          }
        });
    },
  },
  components: {
    VuePerfectScrollbar,
  },
};
</script>
